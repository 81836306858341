import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faKeyboard } from '@fortawesome/pro-duotone-svg-icons'
import { classNames } from '@utils/helpers'

const ShortcutsLearned = ({ compact = false, shortcutsLearnedCount }) => {
  return (
    <div
      className={classNames(
        shortcutsLearnedCount === 0 ? `text-gray-400` : `text-blue-600`,
        `flex items-center space-x-1`,
      )}
    >
      <FontAwesomeIcon icon={faKeyboard} className="text-2xl mr-1" />
      <span className="text-lg font-bold">{shortcutsLearnedCount}</span>
      {!compact && <span>shortcuts learned</span>}
    </div>
  )
}

export default ShortcutsLearned
