import { Fragment } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import Logo from '../../assets/logos/keyhero-logo.svg'
import { useSession } from '@hooks/useSession'
import { classNames } from '@utils/helpers'
import { useCurrentUser } from '@hooks/generated'
import NextLink from '@components/NextLink'
import Image from 'next/image'
import StreakDays from '@components/StreakDays'
import ShortcutsLearned from '@components/ShortcutsLearned'

export default function Navbar() {
  const { session, setAuth } = useSession()
  const { data: user } = useCurrentUser()
  const router = useRouter()
  const basePath = session && session.user ? '/learn' : '/'

  return (
    <Disclosure as="nav" className="bg-white shadow">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex justify-between h-20">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    // <XIcon className="block h-6 w-6" aria-hidden="true" />
                    <p></p>
                  ) : (
                    // <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    <p></p>
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex-shrink-0 flex items-center">
                  <Link href={basePath}>
                    <div className="-mt-[8px] cursor-pointer">
                      <Logo />
                    </div>
                  </Link>
                </div>
                <div className="hidden sm:ml-8 sm:flex sm:space-x-8 flex-1">
                  {session && session.user ? (
                    <Link href="/learn">
                      <a
                        href="#"
                        className={classNames(
                          router?.asPath === '/learn'
                            ? 'border-blue-500 text-blue-500 font-bold'
                            : '',
                          ' text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-lg hover:border-blue-500 hover:text-blue-500',
                        )}
                      >
                        Learn
                      </a>
                    </Link>
                  ) : (
                    <Link href="/pricing">
                      <a
                        href="#"
                        className={classNames(
                          router?.asPath === '/pricing'
                            ? 'border-blue-500 text-blue-500 font-bold'
                            : '',
                          ' text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-lg hover:border-blue-500 hover:text-blue-500',
                        )}
                      >
                        Pricing
                      </a>
                    </Link>
                  )}
                </div>

                <div className="hidden lg:ml-4 lg:flex lg:items-center">
                  {session && !session.user && (
                    <div className="mr-4">
                      <Link href="/login">
                        <a>Login</a>
                      </Link>
                    </div>
                  )}

                  {session && session.user && (
                    <div className="mr-4">
                      {user.me && <StreakDays compact user={user.me} />}
                    </div>
                  )}

                  {session && session.user && (
                    <div className="mr-4">
                      {user.me && (
                        <ShortcutsLearned
                          compact
                          shortcutsLearnedCount={user.me.shortcutsLearnedCount}
                        />
                      )}
                    </div>
                  )}

                  {/* Profile dropdown */}
                  {session && session.user && (
                    <Menu as="div" className="ml-4 relative flex-shrink-0">
                      <div>
                        <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                          <span className="sr-only">Open user menu</span>
                          <div className="w-10 h-10">
                            {user.me && (
                              <Image
                                alt={user.me.username}
                                src={user.me.gravatar}
                                loader={() => {
                                  return user.me.gravatar
                                }}
                                height="100"
                                width="100"
                                className="rounded-full"
                              />
                            )}
                          </div>
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                          <div className="px-1 py-1">
                            <Menu.Item>
                              {({ active }) => (
                                <NextLink
                                  href="/settings"
                                  className={classNames(
                                    active
                                      ? 'bg-blue-500 text-white hover:text-white'
                                      : 'text-gray-900',
                                    'block group w-full items-center rounded-md px-2 py-2 text-sm',
                                  )}
                                >
                                  Settings
                                </NextLink>
                              )}
                            </Menu.Item>
                          </div>
                          <div className="px-1 py-1">
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  onClick={() => {
                                    setAuth(undefined)
                                  }}
                                  className={classNames(
                                    active
                                      ? 'bg-blue-500 text-white'
                                      : 'text-gray-900',
                                    'group flex w-full items-center rounded-md px-2 py-2 text-sm',
                                  )}
                                >
                                  Sign out
                                </button>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  )}
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="pt-2 pb-4 space-y-1"></div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
