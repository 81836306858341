import Navbar from '@components/Layout/Navbar'
import Footer from '@components/Layout/Footer'

interface LayoutProps {
  children: any
  classNameOverride?: string
}

export default function Layout({ children, classNameOverride }: LayoutProps) {
  return (
    <>
      <Navbar />
      <main>
        <div className={classNameOverride || 'my-8 mx-auto max-w-screen-lg'}>
          {children}
        </div>
      </main>
      <Footer />
    </>
  )
}
