import { useEffect, useState } from 'react'
import { isToday, isYesterday, parseISO } from 'date-fns'
import { faFire } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Streak } from '@hooks/generated'
import { classNames } from '@utils/helpers'

const filterByYesterdayOrToday = (streak: Streak) => {
  const insertedAtDate = parseISO(streak.insertedAt)
  return isToday(insertedAtDate) || isYesterday(insertedAtDate)
}

const StreakDays = ({ compact = false, user }) => {
  const [streakDays, setStreakDays] = useState(0)

  useEffect(() => {
    if (user && user.currentStreak) {
      const streaks = user.currentStreak
      if (streaks) {
        const yesterdaysOrTodaysStreaks = streaks.filter(
          filterByYesterdayOrToday,
        )
        if (yesterdaysOrTodaysStreaks && yesterdaysOrTodaysStreaks[0]) {
          setStreakDays(yesterdaysOrTodaysStreaks[0].days)
        }
      }
    }
  }, [user])

  if (user) {
    return (
      <div
        className={classNames(
          streakDays ? `text-amber-600` : `text-gray-400`,
          `flex items-center space-x-1`,
        )}
      >
        <FontAwesomeIcon icon={faFire} swapOpacity className="text-2xl mr-1" />{' '}
        <span className="text-lg font-bold">
          {streakDays ? streakDays : `0`}
        </span>
        {!compact && <span>day streak</span>}
      </div>
    )
  }
}

export default StreakDays
