import Link from 'next/link'

const Footer = () => {
  return (
    <footer className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:space-x-8 lg:px-8">
        <div className="mt-8 md:mt-0 md:order-1">
          <p className="text-center text-base text-gray-400">Keyhero</p>
        </div>
        <div className="mt-4 md:mt-0 md:order-2">
          <p className="text-center">
            <Link href="/shortcuts">Browse shortcuts</Link>
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
